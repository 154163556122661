'use client'

import React from "react"
import { useState } from 'react'
import { Radio, RadioGroup } from '@headlessui/react'

const memoryOptions = [
    { value: true, label: 'Sim' },
    { value: false, label: 'Não' }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function RadioButton() {
    const [mem, setMem] = useState(memoryOptions[2])

    return (

        <RadioGroup value={mem} onChange={setMem} className="mt-2 grid grid-cols-3 gap-3 sm:grid-cols-6">
            {memoryOptions.map((option) => (
                <Radio
                    key={option.value}
                    value={option}
                    className={classNames(
                        'flex items-center justify-center rounded-full bg-white pl-4 pr-4 pt-1 pb-1 text-sm font-semibold text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 data-[checked]:bg-lime-400 data-[checked]:text-gray-900 data-[checked]:ring-0 data-[focus]:data-[checked]:ring-2 data-[focus]:ring-2 data-[focus]:ring-lime-600 data-[focus]:ring-offset-2 data-[checked]:hover:bg-lime-300 sm:flex-1 [&:not([data-focus],[data-checked])]:ring-inset'
                    )}
                >
                    {option.label}
                </Radio>
            ))}
        </RadioGroup>
    )
}
